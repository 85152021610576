var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-control",class:{
    'form-control--icon': _vm.icon,
    'form-control--invalid': _vm.validateError,
    'form-control--symbol': _vm.symbol,
  }},[_c('div',{staticClass:"form-control_relative"},[_c('div',{staticClass:"form-control__input_wrapper"},[(_vm.symbol && (_vm.inFocus || _vm.item))?_c('span',{staticClass:"form-control__symbol"},[_vm._v(_vm._s(_vm.symbol))]):_vm._e(),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formattedValue),expression:"formattedValue"}],staticClass:"form-control__input",class:{
          'form-control__input--symbol': _vm.symbol && (_vm.inFocus || _vm.item),
        },attrs:{"required":"","placeholder":!_vm.inFocus ? _vm.placeholder : '',"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formattedValue)?_vm._i(_vm.formattedValue,null)>-1:(_vm.formattedValue)},on:{"focus":function($event){_vm.inFocus = true},"blur":function($event){_vm.inFocus = false},"change":function($event){var $$a=_vm.formattedValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.formattedValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.formattedValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.formattedValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formattedValue),expression:"formattedValue"}],staticClass:"form-control__input",class:{
          'form-control__input--symbol': _vm.symbol && (_vm.inFocus || _vm.item),
        },attrs:{"required":"","placeholder":!_vm.inFocus ? _vm.placeholder : '',"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.formattedValue,null)},on:{"focus":function($event){_vm.inFocus = true},"blur":function($event){_vm.inFocus = false},"change":function($event){_vm.formattedValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formattedValue),expression:"formattedValue"}],staticClass:"form-control__input",class:{
          'form-control__input--symbol': _vm.symbol && (_vm.inFocus || _vm.item),
        },attrs:{"required":"","placeholder":!_vm.inFocus ? _vm.placeholder : '',"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":(_vm.formattedValue)},on:{"focus":function($event){_vm.inFocus = true},"blur":function($event){_vm.inFocus = false},"input":function($event){if($event.target.composing)return;_vm.formattedValue=$event.target.value}}}),_c('label',{staticClass:"form-control__label"},[_vm._v(" "+_vm._s(_vm.placeholder)+" "),(_vm.required)?_c('span',{staticClass:"form-control__label_required"},[_vm._v("*")]):_vm._e()])]),(_vm.icon)?_c('div',{staticClass:"form-control__icon"},[_vm._t("icon"),_vm._t("btn")],2):_vm._e()]),_vm._t("text"),_c('p',{staticClass:"form-control__error"},[_vm._v(_vm._s(_vm.validateError))])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }